import React from "react";
import { Col, Row } from "react-bootstrap";

import {
  DiJavascript1,
  DiReact,
 
  DiLaravel,
  DiPython,
  DiGit,
  DiPhp,
  DiMysql,
  DiHtml5,
  DiCss3Full,
  DiJqueryUiLogo,
} from "react-icons/di";


  



function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <DiCss3Full />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <DiJqueryUiLogo />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
     
      <Col xs={4} md={2} className="tech-icons">
        <DiLaravel />
      </Col>
     
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
  
      <Col xs={4} md={2} className="tech-icons">
        < DiMysql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPhp />
      </Col>
    </Row>
  );
}

export default Techstack;
