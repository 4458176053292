import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import shopping from "../../Assets/Projects/shop.webp";
import app from "../../Assets/Projects/Screenshot 2024-04-11 192820.png";
import bank from "../../Assets/Projects/bank-img.webp";
import Authority from "../../Assets/Projects/Authorities-side.webp";
import dash from "../../Assets/Projects/dash.jpg";
import students from "../../Assets/Projects/students-side.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={students}
              isBlog={false}
              title="Student side System"
              description="The Student Side System, built with Laravel, Tailwind CSS, and SQL, offers secure user authentication, course management, assignment submission, communication tools, and a comprehensive event calendar for streamlined student management."
              ghLink="https://github.com/kingsley805-tech/student-systems"
              demoLink="https://system.samboachie.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Authority}
              isBlog={false}
              title="Authorities side System"
              description="The Authorities System, developed with Laravel, Tailwind CSS, and SQL, provides secure user authentication, role-based access control, course management, assignment submission, communication tools, and an event calendar for efficient authority management."
              ghLink="https://github.com/kingsley805-tech/school_system"
              demoLink="https://samboachie.com/home"
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bank}
              isBlog={false}
              title="Banking System"
              description="The Banking System, constructed using Laravel, Tailwind CSS, and SQL, offers secure user authentication, transaction management, account tracking, fund transfers, and comprehensive reporting for efficient banking operations."
              ghLink="https://github.com/kingsley805-tech/BANK-USERS"
              demoLink="https://aussie-investments.com.silverbacktech.solutions/"              
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={shopping}
              isBlog={false}
              title="Shopping List"
              description="Building a shopping list using JavaScript and Tailwind CSS offers a dynamic and visually appealing solution for organizing purchases. Utilizing JS for interactivity and Tailwind for styling, this project ensures efficient list management with intuitive user experience.              "
              ghLink="https://github.com/kingsley805-tech/shopping-list"
              demoLink="https://celadon-monstera-2e1412.netlify.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dash}
              isBlog={false}
              title="start-up dashboard"
              description="Crafting a simple dashboard with CSS and HTML presents a straightforward yet effective approach to web design. Leveraging CSS for styling and HTML for structure, this project delivers a visually pleasing and functional dashboard interface tailored to user needs."
              ghLink="https://github.com/kingsley805-tech/Dashboard-Css-html"
              demoLink="https://aquamarine-meerkat-c780c8.netlify.app/"              
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={app}
              isBlog={false}
              title="Chat-App-Design"
              description="Introducing a web chat-app design crafted with Tailwind CSS and HTML: Combining the versatility of Tailwind CSS with the semantic markup of HTML, this project offers a sleek and responsive interface for seamless communication."
              ghLink="https://github.com/kingsley805-tech/Chats--App"
              demoLink="https://sprightly-choux-4a08ef.netlify.app/"              
            />
          </Col>

         

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
